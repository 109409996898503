import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { InMemoryWebApiModule } from "angular-in-memory-web-api";
import { TranslateModule } from "@ngx-translate/core";
import "hammerjs";
import { FuseModule } from "@fuse/fuse.module";
import { FuseSharedModule } from "@fuse/shared.module";
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
} from "@fuse/components";

import { fuseConfig } from "app/fuse-config";

import { FakeDbService } from "app/fake-db/fake-db.service";
import { AppComponent } from "app/app.component";
import { AppStoreModule } from "app/store/store.module";
import { LayoutModule } from "app/layout/layout.module";
import { AuthGuard } from "./main/guards/auth.guard";
import { AppointmentsCalednarComponent } from './main/appointments-calednar/appointments-calednar.component';
import { MatDialogModule } from "@angular/material";
import { ConfirmationDialogComponent } from "./main/confirmation/confirmation-dialog/confirmation-dialog.component";
import { MatTabsModule } from '@angular/material';
const appRoutes: Routes = [

    {
        path: "pages",
        loadChildren: "./main/pages/pages.module#PagesModule",
    },
    {
        path: "auth_user",
        loadChildren:
            "./main/yappes.auth_user/yappes.auth_user.module#YappesAuthUserModule",
        canActivate: [AuthGuard],
    },
    {
        path: "provider",
        loadChildren:
            "./main/yappes.provider/yappes.provider.module#YappesProviderModule",
        canActivate: [AuthGuard],
    },
    {
        path: "availability",
        loadChildren:
            "./main/yappes.availability/yappes.availability.module#YappesAvailabilityModule",
        canActivate: [AuthGuard],
    },
    {
        path: "service",
        loadChildren:
            "./main/yappes.service/yappes.service.module#YappesServiceModule",
        canActivate: [AuthGuard],
    },
    {
        path: "staff",
        loadChildren:
            "./main/yappes.staff/yappes.staff.module#YappesStaffModule",
        canActivate: [AuthGuard],
    },
    {
        path: "service_staff",
        loadChildren:
            "./main/yappes.service_staff/yappes.service_staff.module#YappesServiceStaffModule",
        canActivate: [AuthGuard],
    },
    {
        path: "service_type",
        loadChildren:
            "./main/yappes.service_type/yappes.service_type.module#YappesServiceTypeModule",
        canActivate: [AuthGuard],
    },
    {
        path: "appointments",
        loadChildren:
            () =>
                import('./main/appointments-calednar/appointments-calednar.module').then((m) => m.AppointmentsCalednarModule),
        canActivate: [AuthGuard]
    },
    {
        path: "ap",
        loadChildren:
            () =>
                import('./main/confirmation/confirmation.module').then((m) => m.ConfirmationModule),
    },
    {
        path: "**",
        redirectTo: "pages/auth/login",
    },
];

@NgModule({
    declarations: [AppComponent, ConfirmationDialogComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { useHash: true }),

        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 0,
            passThruUnknownUrl: true,
        }),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatTabsModule,
    
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        AppStoreModule,

    ],
    entryComponents: [ConfirmationDialogComponent],
    bootstrap: [AppComponent],
})
export class AppModule { }
