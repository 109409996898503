import { Injectable, OnDestroy } from "@angular/core";
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
} from "@angular/router";
import { Observable, Subject } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthenticationService } from "../pages/authentication/authentication.service";
import { FuseConfigService } from "@fuse/services/config.service";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { map, takeUntil } from "rxjs/operators";
// import { MatSnackBar } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";
import * as moment from "moment";

@Injectable({
    providedIn: "root",
})
export class AuthGuard implements CanActivate, OnDestroy {
    private _unsubscribeAll = new Subject();
    private config;

    constructor(
        private router: Router,
        private http: HttpClient,
        public authService: AuthenticationService,
        public _fuseConfigService: FuseConfigService,
        // private snackBar: MatSnackBar,
        private _translateService: TranslateService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _fuseNavigationService: FuseNavigationService
    ) {
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res) => {
                this.config = res;
            });
    }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | boolean {
        //   console.log(this.authService.isAuthenticated);
        const params = window.location.hash.split("/");
        console.log()
        if (this.authService.isAuthenticated === true) {
            // this.addChargesNavigation();
            return true;
        } else {
            return this.authService.getProfile().pipe(
                map(
                    (res: any) => {
                        this.authService.isAuthenticated = true;
                        this._fuseConfigService.config = {
                            user: {
                                id: res.id,
                                first_name: res.first_name,
                                last_name: res.last_name,
                                username: res.username,
                                photo: res.photo,
                                role: res.role,
                            },
                        };
                        return true;
                    },
                    (err) => {
                        this.router.navigate(["/"]);
                        return false;
                    }
                )
            );
        }
    }

    calculateDays(fecha_activacion: string, fecha_servidor: string): number {
        const days = moment(fecha_servidor).diff(
            moment(fecha_activacion),
            "days"
        );
        return days;
    }

    addChargesNavigation() {
        if (
            (this.config.isMedic &&
                this.config.responsable.id === this.config.user.id) ||
            (this.config.isSecretary &&
                this.config.responsable.secretarias &&
                this.config.responsable.secretarias.includes(
                    this.config.user.id
                ))
        ) {
            this._fuseNavigationService.updateNavigationItem("charges", {
                hidden: false,
            });
        } else {
            this._fuseNavigationService.updateNavigationItem("charges", {
                hidden: true,
            });
        }
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
