import { FuseNavigation } from "@fuse/types";

export const navigation: FuseNavigation[] = [
    {
        id: "applications",
        title: "Apps",
        translate: "NAV.APPLICATIONS",
        type: "group",
        icon: "apps",
        children: [
            {
                id: "dashboards",
                title: "Providers",
                translate: "Providers",
                type: "item",
                icon: "contact_phone",
                url: "/provider",
            },
            {
                id: "auth",
                title: "Auth user",
                translate: "Auth user",
                type: "item",
                icon: "lock",
                url: "/auth_user",
            },
            {
                id: "service",
                title: "Service",
                translate: "Service",
                type: "item",
                icon: "settings_suggest",
                url: "/service",
            },
            {
                id: "type",
                title: "Service type",
                translate: "Service type",
                type: "item",
                icon: "bubble_chart",
                url: "/service_type",
            },
            {
                id: "staff",
                title: "Staff",
                translate: "Staff",
                type: "item",
                icon: "groups",
                url: "/staff",
            },
            {
                id: "service_staff",
                title: "Service staff",
                translate: "Service staff",
                type: "item",
                icon: "recent_actors",
                url: "/service_staff",
            },
            {
                id: "availability",
                title: "Availability",
                translate: "Availability",
                type: "item",
                icon: "schedule",
                url: "/availability",
            },
            {
                id: "appointments",
                title: "Appointments",
                translate: "Appointments",
                type: "item",
                icon: "today",
                url: "/appointments"
            },
            {
                id: "availability",
                title: "Availability",
                translate: "Log out",
                type: "item",
                icon: "arrow_back",
                url: "/pages/auth/login",
            }
        ],
    },
];
