import { Injectable } from "@angular/core";
import { fuseConfig } from "app/fuse-config";
import { NetworkService } from "app/main/services/network-service.service";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class AuthenticationService {
    public isAuthenticated = false;
    constructor(private networkService: NetworkService) {}

    oauth(): Observable<any> {
        const body = {
            client_id: "cVKybWiewn05l1U41IU1yUPgtRXAcUxEnMh2XUpvrI",
            client_secret: "5KDQhq7VBPJUlMQngFHl5VWui7JDI7PRjE7qoXiM",
            audience: fuseConfig.URL.split("com")[0] + "com",
            grant_type: "client_credentials",
        };
        return this.networkService.post("oauth/token", body);
    }

    login(body): Observable<any> {
        return this.networkService.post("oauth/authenticate", body);
    }

    getProfile(): Observable<any> {
        return this.networkService.get("profile");
    }
}
