import { Injectable, OnDestroy } from "@angular/core";
import {
    HttpClient,
    HttpHeaders,
    HttpEventType,
    HttpParams,
} from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/internal/operators/map";
import { fuseConfig } from "app/fuse-config";
import { FuseConfig } from "@fuse/types";
import { FuseConfigService } from "@fuse/services/config.service";

export interface RequestFileCustom {
    body?: any;
    headers?: HttpHeaders | { [header: string]: string | Array<string> };
    observe?: any;
    params?: HttpParams | { [param: string]: string | Array<string> };
    reportProgress?: boolean;
    responseType?: any;
    withCredentials?: boolean;
}

@Injectable({
    providedIn: "root",
})
export class NetworkService implements OnDestroy {
    private config: FuseConfig;
    private _unsubscribeAll: Subject<any>;
    constructor(
        private HttpClient: HttpClient,
        private _fuseConfigService: FuseConfigService
    ) {
        this._fuseConfigService.getConfig().subscribe((config) => {
            this.config = config;
        });
    }

    get(endpoint): Observable<any> {
        const token =
            localStorage.getItem("authToken") ||
            sessionStorage.getItem("authToken");
        const headers = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                authorization: token
                    ? `Bearer ${token}`
                    : `Bearer ${this.config.API_KEY}`,
                // 'clientkey': `Bearer ${this.config.API_KEY}`,
                "app-authorization": `Bearer ${this.config.API_KEY}`,
            }),
            withCredentials: true,
        };

        return this.HttpClient.get(this.config.URL + endpoint, headers);
    }

    post(endpoint, data): Observable<any> {
        const token =
            localStorage.getItem("authToken") ||
            sessionStorage.getItem("authToken");
        const headers = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: token
                    ? `Bearer ${token}`
                    : `Bearer ${this.config.API_KEY}`,
                // 'clientkey': `Bearer ${this.config.API_KEY}`,
                "App-authorization": `Bearer ${this.config.API_KEY}`,
            }),
            withCredentials: true,
        };

        return this.HttpClient.post(this.config.URL + endpoint, data, headers);
    }

    put(endpoint, data?): Observable<any> {
        const token =
            localStorage.getItem("authToken") ||
            sessionStorage.getItem("authToken");
        const headers = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                authorization: token
                    ? `Bearer ${token}`
                    : `Bearer ${this.config.API_KEY}`,
                // 'clientkey': `Bearer ${this.config.API_KEY}`,
                "app-authorization": `Bearer ${this.config.API_KEY}`,
            }),
            withCredentials: true,
        };

        return this.HttpClient.put(this.config.URL + endpoint, data, headers);
    }

    patch(endpoint, data?): Observable<any> {
        const token =
            localStorage.getItem("authToken") ||
            sessionStorage.getItem("authToken");
        const headers = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                authorization: token
                    ? `Bearer ${token}`
                    : `Bearer ${this.config.API_KEY}`,
                // 'clientkey': `Bearer ${this.config.API_KEY}`,
                "app-authorization": `Bearer ${this.config.API_KEY}`,
            }),
            withCredentials: true,
        };

        return this.HttpClient.patch(this.config.URL + endpoint, data, headers);
    }

    delete(endpoint): Observable<any> {
        const token =
            localStorage.getItem("authToken") ||
            sessionStorage.getItem("authToken");
        const headers = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                authorization: token
                    ? `Bearer ${token}`
                    : `Bearer ${this.config.API_KEY}`,
                // 'clientkey': `Bearer ${this.config.API_KEY}`,
                "app-authorization": `Bearer ${this.config.API_KEY}`,
            }),
            withCredentials: true,
        };

        return this.HttpClient.delete(this.config.URL + endpoint, headers);
    }

    file(endpoint, formdata): Observable<any> {
        const headers: RequestFileCustom = {
            headers: new HttpHeaders({
                authorization: `Bearer ${this.config.API_KEY}`,
                // 'clientkey': `Bearer ${this.config.API_KEY}`,
                "app-authorization": `Bearer ${this.config.API_KEY}`,
            }),
            withCredentials: true,
            reportProgress: true,
            observe: "events",
        };

        return this.HttpClient.post(
            this.config.URL + endpoint,
            formdata,
            headers
        ).pipe(
            map((event: any) => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        const progress = Math.round(
                            (100 * event.loaded) / event.total
                        );
                        return { status: "progress", message: progress };

                    case HttpEventType.Response:
                        return event.body;

                    default:
                        return `Unhandled event: ${event.type}`;
                }
            })
        );
    }

    getFile(endpoint): Observable<any> {
        const headers: RequestFileCustom = {
            headers: new HttpHeaders({
                authorization: `Bearer ${this.config.API_KEY}`,
                // 'clientkey': `Bearer ${this.config.API_KEY}`,
                "app-authorization": `Bearer ${this.config.API_KEY}`,
            }),
            withCredentials: true,
            responseType: "blob",
        };

        return this.HttpClient.get(this.config.URL + endpoint, headers);
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
