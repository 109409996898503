import { FuseConfig } from "@fuse/types";
import { environment as env } from "../../environments/environment";

/**
 * Default Fuse Configuration
 *
 * You can edit these options to change the default options. All these options also can be
 * changed per component basis. See `app/main/pages/authentication/login/login.component.ts`
 * constructor method to learn more about changing these options per component basis.
 */

export const fuseConfig: FuseConfig = {
    // Color themes can be defined in src/app/app.theme.scss
    colorTheme: "theme-default",
    customScrollbars: true,
    layout: {
        style: "vertical-layout-1",
        width: "fullwidth",
        navbar: {
            primaryBackground: "fuse-navy-700",
            secondaryBackground: "fuse-navy-900",
            folded: false,
            hidden: false,
            position: "left",
            variant: "vertical-style-2",
        },
        toolbar: {
            customBackgroundColor: false,
            background: "fuse-white-500",
            hidden: false,
            position: "below-static",
        },
        footer: {
            customBackgroundColor: true,
            background: "fuse-navy-900",
            hidden: true,
            position: "below-fixed",
        },
        sidepanel: {
            hidden: true,
            position: "right",
        },
    },
    URL: !env.production
        ? "https://devyappes.binwus.com/app/api/v1/"
        : "/app/api/v1/",
    API_KEY: "",
    user: {
        id: "4a89d954-1e1b-5e97-b978-73ad7283eb58",
        first_name: "Admin",
        last_name: "Yappes DEV",
        username: "admin",
        photo: "",
        role: ["admin"],
    },
};
